import React, { useEffect } from "react";
import PraxisTemplateDetailed from "../../template/PraxisTemplateDetailed";

function PraxisWarnke() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const praxisInfos = {
    fakultat: "Dermatologie",
    doctor: "Frau Theresa Warnke",
    title: "Fachärztin für Haut- und Geschlechtskrankheiten",
    services: [],
    extendedNotes: [
      "Wenn Sie in unserer Praxis keinen zeitnahen Termin erhalten oder Neupatient sind, nutzen Sie bitte die Terminservicestelle der Kassenärztlichen Vereinigung (KV).",
      "Buchen Sie über https://eterminservice.de/terminservice oder telefonisch: 116117 mit dem VERMITTLUNGSCODE Ihres Hausarztes.",
      "Bei akuten Beschwerden sollten Sie sich zunächst an Ihren Hausarzt wenden, der eine Praxisvorstellung bei Bedarf beschleunigen kann.",
    ],
    notes:
      "Termine werden grundsätzlich nur nach Vereinbarung vergeben, nicht abgesagte Termine werden privat in Rechnung gestellt.",
    street: "Freiherr-vom-Stein-Str. 2",
    city: "Falkenberg/Elster",
    postalCode: "04895",
    phone: "035365 – 639 901",
    fax: "035365 – 639 903",
    officeTimes: [
      { Mo: "Mo: 8:00 - 16:00 Uhr" },
      { Di: "Di: 8:00 – 15:00 Uhr" },
      { Mi: "Mi: 8:30 - 18:30 Uhr" },
      { Do: "" },
      { Fr: "" },
    ],
    useNewOfficeTimes: false,
    imgList: [],
    downloads: { title: "Anamnesebogen", file: "Anamnesebogen_Derma.pdf" },
  };

  return <PraxisTemplateDetailed praxisInfos={praxisInfos} />;
}

export default PraxisWarnke;
