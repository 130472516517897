import React, { useEffect } from "react";
import PraxisTemplate from "../../template/PraxisTemplate";

function PraxisRost() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const praxisInfos = {
    fakultat: "Innere Medizin",
    doctor: "Herr Mirko Rost",
    title:
      "Facharzt für Innere Medizin/Hausarzt Notfallmedizin und Palliativmedizin",
    services: [
      "EKG auch als Monitoring",
      "LZ-EKG",
      "LZ-RR",
      "Fahrradergometrie (Belastungs-EKG)",
      "Spirometrie (Lungenfunktion)",
      "Sonographieleistungen: Abdomen, Schilddrüse, Thorax",
      "Als IGeL: Echokardiographie, Doppler/Duplex, Extracraniell, Peripher arteriell/venös",
    ],
    street: "Karl-Marx-Str. 104",
    city: "Calau",
    postalCode: "03205",
    phone: "03541 – 801 991",
    fax: "03541  -801 993",
    officeTimes1: [
      {
        displayName: "Montag",
        Mo: [
          {
            id: "1",
            time: "8:00 - 10:00 Uhr",
            note: "offene Sprechstunde",
          },
          {
            id: "2",
            time: "10:00 - 12:00 Uhr",
            note: "Terminsprechstunde",
          },
          {
            id: "3",
            time: "14:00 - 15:00 Uhr",
            note: "offene Sprechstunde",
          },
          {
            id: "4",
            time: "15:30 - 18:00 Uhr",
            note: "Terminsprechstunde",
          },
        ],
      },
      {
        displayName: "Dienstag",
        Di: [
          {
            id: "1",
            time: "7:30 - 10:00 Uhr",
            note: "offene Sprechstunde",
          },
          {
            id: "2",
            time: "10:00 - 11:30 Uhr",
            note: "Terminsprechstunde",
          },
          {
            id: "3",
            time: "",
            note: "",
          },
          {
            id: "4",
            time: "",
            note: "",
          },
        ],
      },
      {
        displayName: "Mittwoch",
        Mi: [
          {
            id: "1",
            time: "8:00 - 10:00 Uhr",
            note: "offene Sprechstunde",
          },
          {
            id: "2",
            time: "10:00 - 12:00 Uhr",
            note: "Terminsprechstunde",
          },
          {
            id: "3",
            time: "",
            note: "",
          },
          {
            id: "4",
            time: "",
            note: "",
          },
        ],
      },
      {
        displayName: "Donnerstag",
        Do: [
          {
            id: "1",
            time: "8:00 - 10:00 Uhr",
            note: "Ultraschall und nach Vereinbarung",
          },
          {
            id: "2",
            time: "",
            note: "",
          },
          {
            id: "3",
            time: "13:00 - 14:00 Uhr",
            note: "offene Sprechstunde",
          },
          {
            id: "4",
            time: "14:00 - 16:00 Uhr",
            note: "Terminsprechstunde",
          },
        ],
      },
      {
        displayName: "Freitag",
        Fr: [
          {
            id: "1",
            time: "8:00 - 9:00 Uhr",
            note: "offene Sprechstunde",
          },
          {
            id: "2",
            time: "9:00 - 11:00 Uhr",
            note: "Terminsprechstunde",
          },
          {
            id: "3",
            time: "",
            note: "",
          },
          {
            id: "4",
            time: "",
            note: "",
          },
        ],
      },
    ],
    useNewOfficeTimes: true,
    imgList: [],
    officeTimes: [
      { Mo: "Mo: 8:00 – 10:00 Uhr und 14:00 – 15:00 Uhr" },
      { Di: "Di: 7:30 – 10:00 Uhr" },
      { Mi: "Mi: 8:00 – 10:00 Uhr" },
      { Do: "Do: 13:00 – 14:00 Uhr" },
      { Fr: "Fr: 8:00 – 9:00 Uhr" },
    ],
  };

  return <PraxisTemplate praxisInfos={praxisInfos} />;
}

export default PraxisRost;
