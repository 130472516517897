import React, { useEffect } from "react";
import PraxisTemplate from "../../template/PraxisTemplate";

function PraxisMetzger() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const praxisInfos = {
    fakultat: "Innere Medizin",
    doctor: "Frau Erika Metzger",
    title: "Fachärztin für Innere Medizin/Hausärztin, ZB Notfallmedizin",
    services: [
      "Langzeit-Blutdruck",
      "Ruhe-EKG",
      "Spirometrie (Lungenfunktionen)",
      "Kurzwellentherapie",
      "Impfungen",
      "bei Bed. Hausbesuche",
      "Heimbesuche",
      "Kontrolluntersuchungen DMP Diabetes mellitus, KHK, COPD + Asthma",
      "Hausarztzentrierte Versorgung (AOK, Knappschaft)",
      "psychosomatische Grundversorgung",
    ],
    notes:
      "bei Notwendigkeit: Hausbesuche und Mitbehandlung von Heimpatienten möglich",
    street: "Hauptstraße 31",
    city: "Großthiemig",
    postalCode: "04932",
    phone: "035343  -789 003",
    fax: "035343 – 789 004",
    officeTimes: [
      {
        Mo: "Mo: 8:00 – 13:00 Uhr",
      },
      { Di: "Di: 8:00 – 13:00 Uhr" },
      { Mi: "Mi: nach Vereinbarung" },
      { Do: "Do: 8:00 – 13:00 Uhr" },
      { Fr: "Fr: 8:00 – 13:00 Uhr" },
    ],
    useNewOfficeTimes: false,
    imgList: [
      "ErikaMetzgerPraxisGrossthiemig1-768x512.webp",
      "ErikaMetzgerPraxisGrossthiemig4-768x512.webp",
      "ErikaMetzgerPraxisGrossthiemig7-768x512.webp",
      "ErikaMetzgerPraxisGrossthiemig4-768x512.webp",
    ],
  };

  return <PraxisTemplate praxisInfos={praxisInfos} />;
}

export default PraxisMetzger;
