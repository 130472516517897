import React, { useEffect } from "react";
import PraxisTemplateDetailed from "../../template/PraxisTemplateDetailed";

function PraxisRichter() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const praxisInfos = {
    fakultat: "Dermatologie",
    doctor: "Dr. med. Susanne Richter",
    title: "Fachärztin für Haut- und Geschlechtskrankheiten",
    services: [
      {
        title: "Klassische Dermatologie",
        description: [
          "Der Schwerpunkt unserer Tätigkeit liegt in der klassischen Dermatologie. Die Haut ist mit 1,5 bis 2 Quadratmeter das größte Organ des menschlichen Körpers. Als Grenzorgan zur Umwelt ist sie beständig äußeren Einflüssen ausgesetzt, weshalb sie vielen als „Spiegel der Seele“ gilt. Die klassische Dermatologie umfasst die Diagnose, Behandlung und Verhütung von Erkrankungen der Haut, der hautnahen Schleimhäute, Haare und Nägel. Oft handelt es sich um harmlose, aber dennoch störende oder belastende Erscheinungen. Andererseits nehmen etwa Hauttumore und allergisch bedingte Hauterkrankungen immer weiter zu.",
          "Zur Behandlung dermatologischer Krankheiten steht uns ein großes therapeutisches Spektrum zur Verfügung. Es umfasst neben der Lokalbehandlung und Systemtherapie auch physikalische Methoden wie Kryo-, Laser- oder Lichttherapie bis hin zur Dermatochirurgie.",
        ],
      },
      {
        title: "Hautkrebsvorsorge und Tumor-Nachsorge",
        description: [
          "Vorsorge-Leistung aller gesetzlichen Krankenkassen ab dem 35. Lebensjahr (alle 2 Jahre), bei einzelnen Kassen auch schon vor dem 35. Lebensjahr, Dermatoskopie (Lupen-Untersuchung, medizinisch sinnvolle Ergänzung v.a. bei auffälligen Pigmentmalen) üblicherweise separat kostenpflichtig",
        ],
      },
      {
        title: "Allergologie",
        description: [
          "Diagnostik und Therapie allergischer Erkrankungen, allergologische Testungen (Pricktest, Epikutantest, RAST-Untersuchungen) und spezifische Immuntherapie (»Hyposensibilisierung«, z.b. bei Pollen-Allergie oder Hausstaubmilben-Allergie)",
        ],
      },
      {
        title: "Hyposensibilisierungs-Behandlung",
        description: [
          "Spezifische Immuntherapie – eine spezielle Behandlung von z.B. Pollen- und Hausstauballergien (Dauer 3-5 Jahre), Behandlungsprinzip ähnlich dem einer Impfung; sehr gute Erfolgsquoten (> 80 Prozent), bei Pollenallergie auch Kurzzeittherapie im Winter möglich (Dauer immer mindestens 3 Jahre oder 3 Winter hintereinander)",
        ],
      },
      {
        title: "Ambulante Operationen",
        description: [
          "Entfernung von verdächtigen Pigmentmalen, hellen Hautkrebs (Basaliom, Spinaliom u.a.), ggf. auch von gutartigen Veränderungen. Ob die Kosten von Ihrer Krankenkasse übernommen werden oder ggf. Selbstzahler-Leistungen sind, wird Ihnen in jedem Fall vorher mitgeteilt.",
        ],
      },
      {
        title: "UV-Lichttherapie",
        description: [
          "Entzündliche Hauterkrankungen sind für Betroffene oft schwerwiegend. Eine Lichttherapie mittels ultravioletter Strahlen kann hier wirkungsvoll helfen.",
          "Patienten mit Neurodermitis, Schuppenflechte, Knötchenflechte, Weißfleckenerkrankung und ähnlichen Krankheitsbildern können in unserer Praxis erfolgreich mit speziellen Wellenlängen des UV-Lichtes bestrahlt werden.",
          "Bei Patienten mit Sonnenallergie kann vor Beginn der sonnenreichen Zeit durch uns sehr erfolgreich eine Hardeningtherapie (Abhärtungsbestrahlung) durchgeführt werden. Diese erfolgt mittels medizinischer UV-Bestrahlungstherapie.",
          "Für jeden Patienten wird hierbei stets ein individueller Behandlungsplan erstellt. Alternativ bieten Lichttherapien eine ebenso milde wie effektive Therapiemöglichkeit.",
        ],
      },
      {
        title: "Photodynamische Therapie",
        description: [
          "Behandlung von bestimmten Formen des hellen Hautkrebs und seinen Vorstufen mittels einer photoaktiven Substanz (in Cremeform). Durch das Tageslicht wird die lichtsensibilisierende Substanz aktiviert und es kommt zu einer gezielten Zerstörung der Tumorzellen.",
          "Der Vorteil der schonenden Tageslicht-PDT besteht in der schmerzarmen und dennoch sehr effektiven Therapie der Lichtschäden unter Schonung der gesunden Haut. Sehr gute kosmetische Ergebnisse, Selbstzahlerleistung für gesetzlich Versicherte, von privaten Krankenversicherungen und Berufsgenossenschaften werden die Kosten übernommen.",
        ],
      },
      {
        title: "Medizinische Laser-Behandlung",
        description: [
          "Fraktionierter CO2-Laser als Abtragungs-Laser (störende Hautveränderungen, Pigmentflecken u.a.). [Selbstzahlerleistung]",
        ],
      },
      {
        title: "Berufsdermatologie",
        description: [
          "Berufsbedingte Hauterkrankungen wie Handekzeme zwingen viele Betroffene zur Berufsaufgabe. Wir nutzen intensive Therapiemöglichkeiten.",
          "Auslöser berufsbedingter Hauterkrankungen sind Allergien gegen Arbeitsstoffe oder kontinuierliche und wiederkehrende Nassarbeiten. Grundlage der Erkrankung ist oft eine individuell erhöhte Empfindlichkeit. Um Ihre Arbeitsfähigkeit zu erhalten, kümmern wir uns nicht nur um die Diagnostik und Behandlung bestehender Erkrankungen, sondern auch um die Prävention. Wir beraten Sie eingehend zu Hautschutz und Hautpflege.",
          "Viele Erwerbstätige arbeiten im Freien und sind dabei als “Outdoor-Worker” intensiver Sonnenbestrahlung ausgesetzt. Bei diesen Menschen kommt es zum vermehrten Auftreten von Hautkrebs und seiner Vorläuferstufen. Hierbei handelt es sich um eine berufsbedingte Hauterkrankung, für die als Kostenträger die Berufsgenossenschaften eintreten. Diese bieten vielfach ein deutlich größeres Leistungsspektrum als die gesetzlichen Krankenkassen.",
        ],
      },
    ],
    extendedNotes: [
      "Wenn Sie in unserer Praxis keinen zeitnahen Termin erhalten oder Neupatient sind, nutzen Sie bitte die Terminservicestelle der Kassenärztlichen Vereinigung (KV).",
      "Buchen Sie über https://eterminservice.de/terminservice oder telefonisch: 116117 mit dem VERMITTLUNGSCODE Ihres Hausarztes.",
      "Bei akuten Beschwerden sollten Sie sich zunächst an Ihren Hausarzt wenden, der eine Praxisvorstellung bei Bedarf beschleunigen kann.",
    ],
    notes:
      "Termine werden grundsätzlich nur nach Vereinbarung vergeben, nicht abgesagte Termine werden privat in Rechnung gestellt.",
    street: "Freiherr-vom-Stein-Str. 2",
    city: "Falkenberg/Elster",
    postalCode: "04895",
    phone: "035365 – 639 901",
    fax: "035365 – 639 903",
    officeTimes: [
      { Mo: "Mo: 8:00 – 12:00 und 13:00 – 17:00 Uhr" },
      { Di: "Di: 8:00 – 14:30 Uhr" },
      { Mi: "Mi: OP Tag/ Vereinbarung" },
      { Do: "Do: OP Tag" },
      { Fr: "Fr: 8:00 – 11:30 Uhr" },
    ],
    useNewOfficeTimes: false,
    imgList: [
      "dermatologie_team.webp",
      "dermatologie_auszubildene.webp",
      "PraxisRichterMvzMediFalkenberg1-768x512.webp",
      "PraxisRichterMvzMediFalkenberg2-768x512.webp",
    ],
    downloads: { title: "Anamnesebogen", file: "Anamnesebogen_Derma.pdf" },
  };

  return <PraxisTemplateDetailed praxisInfos={praxisInfos} />;
}

export default PraxisRichter;
