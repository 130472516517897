import React, { useEffect } from "react";
import PraxisTemplate from "../../template/PraxisTemplate";

function PraxisMeier() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const praxisInfos = {
    fakultat: "Kinder- und Jugendmedizin",
    doctor: "Dr. med. Gerrit Meier",
    title: "Praktische Ärztin/ Fachärztin für Kinder- und Jugendmedizin",
    street: "Katharinenstr. 2",
    city: "Herzberg/Elster",
    postalCode: "04916",
    phone: "03535 - 485 906 7",
    fax: "03535 - 493 871 6",
    officeTimes: [
      { Mo: "Mo: 8:00 – 12:00 Uhr und 15:00 – 17:00 Uhr" },
      { Di: "Di: 8:00 – 12:00 Uhr und nach Vereinbarung" },
      { Mi: "Mi: 8:00 – 12:00 Uhr" },
      { Do: "Do: 8:00 – 12:00 Uhr und 14:00 - 16:00 Uhr" },
      { Fr: "Fr: 8:00 – 12:00 Uhr" },
    ],
    useNewOfficeTimes: false,
    imgList: [],
  };

  return <PraxisTemplate praxisInfos={praxisInfos} />;
}

export default PraxisMeier;
